@use '~@edwin-edu/ui/vars' as *;

.collectionDetailsForm {
  flex: 0 0 440px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 6px 9px 0px 14px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 3px 0px;
  border-radius: 3px;
  margin-right: 5px;
  height: 100%;
}

.collectionDetailsContainer {
  flex: 0 0 auto;
  position: relative;
  height: 100%;
  padding-top: 53px;
  overflow: visible;

  form {
    border: 1px solid #e5e7eb;
  }
}

.scrollContainer {
  overflow-y: auto;
  @include scrollbar();
}

.collectionTitle {
  color: rgb(1, 122, 204);
  font-size: 26px;
  font-weight: 400;
  width: 100%;
  margin: 5px 0px;
  padding: 0px;
  border-bottom: 1px solid rgb(216, 216, 216);
}

.descriptionSection {
  margin-bottom: 20px;

  div[class="DraftEditor-editorContainer"] {
    z-index: unset;
  }
}

.subjectText {
  margin-bottom: 14px;
  font-weight: 600;
  font-size: 14px;
}

.inputTitle {
  border: none;
  color: $color-neutral-500;
  font-weight: 300;
  font-style: italic;
  letter-spacing: -0.6px;
  font-size: 20px;
  height: 70px;
  outline: 1px solid $color-neutral-500;
  &::placeholder {
    color: rgb(1, 122, 204);
  }
  &:focus {
    outline: 1px solid $color-neutral-500;
    box-shadow: none;
  }
}

.lastUpdated {
  margin-bottom: 26px;
  font-size: 12px;
  color: rgb(74, 74, 74);
  letter-spacing: -0.3px;
}

.teacherOnly {
  position: relative;
}

.formDivider {
  margin: 0 86px 3px -1px;
  padding: 0;
  opacity: 0.8;
}

.richTextEditorHeading {
  font-size: 16px;
  color: #017acc;
  letter-spacing: -0.4px;
  font-weight: 600;
}

.tagsHeader {
  margin: 10px 0;
}

.tagsContainer {
  margin-bottom: 16px;
}

.expandIcon {
  width: 50px;
  height: 25px;
  background-color: #79afe1;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #b3b3b3;
  }
}

.subjectDropdown {
  border: 1px solid rgb(1, 122, 204);
  border-radius: 20px;
  font-size: 16px;
  font-weight: 600;

  & > button {
    width: 100%;
    text-align: left;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.2);
    border: none;
    color: rgb(1, 122, 204);
    box-shadow: none;
    cursor: pointer;
    transition: border-color 100ms ease-in 0s;
    padding: 0 10px;
    min-height: 38px;
    overflow-y: scroll;
  }

  & :global(.menu) {
    padding-top: 0;
    left: -1px;
  }

  & :global(.menu-group) {
    button,
    div:first-child:global(.menu-group-title) {
      height: auto;
      font-size: 16px;
    }

    button {
      padding: $space-2x $space-3x;
    }

    div:first-child:global(.menu-group-title) {
      padding: $space-3x $space-3x 0;
      color: $color-neutral-300;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 13px;
    }
  }
}

.tagsList {
  font-style: italic;
}
.tag {
  display: inline-block;
  margin-right: 10px;
}
