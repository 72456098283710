@use '@edwin-edu/ui/vars' as *;

.categoryTitle {
  color: $color-neutral-400;
  font-weight: 700;
  margin-bottom: $space-2x;
}

.disabled {
  opacity: .5;
}
