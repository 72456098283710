@use '~@edwin-edu/ui/vars' as *;
@use 'sass:math';

.button {
  display: flex;
  flex: 0 1 auto;
  justify-content: flex-start;
  align-items: center;
  width: 220px;
  height: 72px;
  background: $white;
  padding: $space-1x $space-3x;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 8px;
  &:focus {
    outline: 2px solid $color-base-400;
  }
}

.icon {
  height: 26px;
  width: 26px;
}

$fontSize: 16;
$lineHeight: 1.2;
$maxLines: 3;
$maxHeight: math.round($fontSize * $lineHeight * $maxLines + 2)px; // extra 2 px is padding, just in case

.description {
  display: flex;
  flex-direction: column;
  font-size: $fontSize;
  font-weight: 600;
  letter-spacing: -0.525px;
  color: $black;
  white-space: break-spaces;
  word-break: break-word;
  transition: color 150ms;
  text-align: left;
  align-content: center;
  margin-left: 8px;
  overflow: hidden;
  line-height: $lineHeight;
  max-height: $maxHeight;
}

.small {
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: $maxLines;
  line-clamp: $maxLines;
  -webkit-box-orient: vertical;
}
