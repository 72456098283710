.NotFoundContainer {
  display: flex;
  align-items: center;
  height: 100vh;
}
.NotFoundText {
  font-size: 42px;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: -0.30000001192092896px;
  text-align: left;  
}
.NotFoundSubTextContainer {
  margin-top: 20px;
}
.NotFoundSubText {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.30000001192092896px;
  text-align: left;

}
.NotFoundSVGContainer {
  margin-right: 50px;
}
.NotFoundButton {
  margin-top: 20px;
}