@use '~@edwin-edu/ui/vars' as *;

.scroller {
  height: 100%;
  overflow: auto;
  @include scrollbar;
}

.innerWrapper {
  max-width: 1400px;
  width: 1400px;
  min-width: 880px;
  margin: 0 auto;
  padding: $space-6x $space-6x 0;
}
