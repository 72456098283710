@use '@edwin-edu/ui/vars' as *;

.visuallyHidden {
  @include visuallyHidden;
}

.checklistAccordionLabel {
  flex: 0 0 auto !important;
  width: 44px !important;
  cursor: pointer !important;
  display: flex !important;
  align-items: start !important;
  justify-content: center !important;
  padding-top: 6px !important;
}

.checklistAccordionButton {
  font-weight: 400 !important;
  flex: 1 !important;
  line-height: 1.25 !important;
  text-align: left !important;
}

.checked {
  font-weight: 700 !important;
}
