@use '@edwin-edu/ui/vars' as *;

.backContainer {
  position: absolute;
  left: -36px;

  @media (max-width: 1490px) {
    left: 8px;
  }
}

.backButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: $space-2x;
  font-weight: 600;
  font-size: 16px;
  border-right: 1px solid $color-neutral-200;
  padding-right: $space-3x;
  margin-right: $space-3x;
}

.backArrow {
  width: 14px;
  height: auto;

  g {
    stroke: $color-base-300;
  }
}

.hideCovered {
  color: $color-neutral-500;
}

.content {
  overflow-y: auto;
  overflow-x: hidden;
  
  @include scrollbar;
}

.filterContainer {
  min-width: 611px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
}