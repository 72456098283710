@use '@edwin-edu/ui/vars' as *;

.filtersHeader {
  position: absolute;
  top: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $space-2x;
}

.reset {
  position: absolute;
  top: 0;
  right: $space-2x;
  font-weight: 600;
  font-size: 14px;
  padding: $space-3x;
  z-index: 1;
  color: $color-neutral-500 !important;
}

.reset:hover {
  color: $color-base-400 !important;
}

.curriculumFilters {
  position: relative;
  flex: 0 0 326px;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(128.8deg, #dcf2ff 25.7%, #eaf8ff 81.5%);
  opacity: 0.9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 6px;
  font-size: 16px;
}

.filtersScroll {
  position: relative;
  padding: 20px 45px 20px 30px;
  overflow-y: auto;
  overflow-x: hidden;
  @include scrollbar;
}

.content {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  
  @include scrollbar;
}

.emptyResults {
  position: relative;
  @include gradientBorderStyles(4, 1);
}
