.collectionsOrderedList {
  padding: 0 0 100px 0;
  margin: 0;
  list-style: none;
  width: 100%;
}

.collectionsDropdownContainer {
  margin-left: 7;
  padding-top: 0;
}
