@import '~@edwin-edu/ui/vars';

.clearButton {
  position: absolute;
  top: -5px;
  left: 227px;
  padding: $space-2x;
  box-shadow: $shadow-neutral-0;
  border: none;
  border-radius: $radius-1x;
}
