@use '~@edwin-edu/ui/vars' as *;

.interactiveToolContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.separator {
  margin: 10px 0 30px;
  border-bottom: 1px solid black;
  width: 100%;
}

.desmosTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
